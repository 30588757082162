<template>
    <span class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white-text rounded-full capitalize">{{ item.visibilty }}</span>
</template>

<script>
export default {
    name: "badge",
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style lang="scss" scoped></style>
